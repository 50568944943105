/* eslint @typescript-eslint/prefer-nullish-coalescing: 1 -- Do not want to break existing code by switching to nullish coalesce blidnly */
//TODO: GROW-2166 refactor and core component move when productizing
import type { FooterV3Props, FootnotesProps } from '@jouzen/ecom-components';
import { FooterV3, Footnotes } from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';
import { useTranslations } from 'next-intl';
import { type ComponentPropsWithoutRef, type ReactNode } from 'react';

import { headerInversePageLevel } from '../data/headerInversePageLevel';
import { HeaderV4, type HeaderV4Props } from '../HeaderV4';

export const MAIN_CONTENT_ID = 'main-content';

interface EyebrowProps extends ComponentPropsWithoutRef<'div'> {
  readonly displayMessage: () => ReactNode;
}

interface PageContainerProps extends ComponentPropsWithoutRef<'div'> {
  children: ReactNode;
  /** optional additional classNames */
  className?: string;
  /** optional hidden h1 (for screen reader) */
  h1?: string;
  /** name of page */
  name: string;
  /** padding control */
  padding: 'top' | 'bottom' | 'both' | 'none';
}

export interface LayoutV4Props {
  readonly children: ReactNode;
  readonly className?: string;
  readonly containerProps: Omit<PageContainerProps, 'children'>;
  readonly eyebrowProps?: EyebrowProps;
  readonly footnotesProps?: FootnotesProps;
  readonly headerProps: HeaderV4Props;
  readonly PassedInHeader?: ReactNode;
  readonly hideFooter?: boolean; // deprecate in favor of footerProps.hidden
  readonly hideHeader?: boolean;
  readonly footerProps: FooterV3Props;
  readonly mainLabel: string;
  readonly pageName?: string;
  readonly Header?: (args: HeaderV4Props) => JSX.Element;
}

function Eyebrow({
  className,
  displayMessage: DisplayMessage,
  ...props
}: EyebrowProps): JSX.Element {
  return (
    <div
      className={cx(
        'ui-py-3.5 ui-px-1 ui-text-center ui-pointer-events-auto ui-relative ui-z-10',
        className,
      )}
      data-cy="eyebrow-cta-message"
      {...props}
    >
      <DisplayMessage />
    </div>
  );
}

export function PageLayoutV4({
  children,
  className = '',
  containerProps,
  eyebrowProps,
  footnotesProps,
  headerProps,
  hideFooter = false, // deprecate in favor of footerProps.hidden
  hideHeader = false,
  footerProps,
  mainLabel,
  Header = HeaderV4,
}: LayoutV4Props): JSX.Element {
  const t = useTranslations();
  const {
    name = '',
    padding = 'both',
    className: containerClassName = '',
    h1 = undefined,
  } = containerProps;
  const pageLevelInverse = headerInversePageLevel[containerProps.name] ?? false;

  return (
    <div>
      <div className="ui-sr-only focus-within:ui-not-sr-only focus-within:ui-py-2 focus-within:ui-px-4">
        <a data-cy="skip-to-content" href={`#${MAIN_CONTENT_ID}`}>
          {t('header_skip_to_content')}
        </a>
      </div>
      <div
        className={cx(className, 'ui-flex ui-flex-col ui-min-h-screen')}
        data-cy="page-wrapper"
        id="page-wrapper"
      >
        <div className="ui-relative ui-pointer-events-none ui-grow">
          {eyebrowProps ? <Eyebrow {...eyebrowProps} /> : null}
          <div className="ui-absolute ui-top-auto ui-bottom-auto ui-w-full ui-h-full ui-pointer-events-none px-4">
            {!hideHeader && (
              <Header {...headerProps} inverse={pageLevelInverse} />
            )}
          </div>
          <main
            aria-label={mainLabel}
            className="ui-pointer-events-auto"
            data-cy={MAIN_CONTENT_ID}
            id={MAIN_CONTENT_ID} // needed for "skip to content" link
            tabIndex={-1} // makes the element focusable without including it in the tab order
          >
            <div
              className={cx({
                'ui-pt-36': padding === 'top',
                'ui-pb-16': padding === 'bottom',
                'ui-pt-36 ui-pb-16': padding === 'both',
                [containerClassName]: Boolean(containerClassName),
              })}
              data-cy={`page-${name}`}
            >
              {h1 ? (
                <h1 className="ui-sr-only" data-cy="container-h1">
                  {h1}
                </h1>
              ) : null}
              {children}
            </div>
            {footnotesProps ? <Footnotes {...footnotesProps} /> : null}
          </main>
        </div>

        <FooterV3
          {...footerProps}
          // TODO: remove after deprecating hideFooter
          hidden={footerProps.hidden || hideFooter}
        />
      </div>
    </div>
  );
}

PageLayoutV4.displayName = 'PageLayoutV4';
