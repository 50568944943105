'use client';

import { useDynamicConfig } from '@statsig/react-bindings';

import { FITNESS_PATHNAME, POP_PATHNAME } from '@/app/consts/pathnames';
import usePathnameWithoutLocale from '@/app/hooks/usePathnameWithoutLocale';

import type { GenericEyebrowProps } from '../EyebrowMessage';
import { AffiliateMessage, GenericEyebrow } from '../EyebrowMessage';
import type { EyebrowConfig, EyebrowProps } from '../types';

const useEyebrowProps = (): EyebrowProps => {
  const config = useDynamicConfig('eyebrow');

  const eyebrowConfig = config.value as EyebrowConfig;

  const activeEyebrowConfig = Object.keys(config.value).length > 0;

  const className =
    eyebrowConfig.className ?? 'bg-sandstone-200 text-sandstone-500';

  // Display the hidden afilliate eyebrow by default
  const DEFAULT = {
    displayMessage: AffiliateMessage,
    className: 'hidden',
    id: 'partner_eyebrow',
  };
  const pathnameWithoutLocale = usePathnameWithoutLocale();

  // organize by pathname and statsig config
  const routes = eyebrowConfig.routes;

  const activeConfigPath = routes?.length
    ? routes.includes(pathnameWithoutLocale)
    : pathnameWithoutLocale === '/';

  if (activeEyebrowConfig && activeConfigPath) {
    return {
      //Default class name styles for the eyebrow if no classname in config is provided
      className: className,
      displayMessage: () => (
        <GenericEyebrow {...(config.value as unknown as GenericEyebrowProps)} />
      ),
    };
  }

  // Display the hidden affiliate eyebrow by default
  if (
    pathnameWithoutLocale === POP_PATHNAME ||
    pathnameWithoutLocale === FITNESS_PATHNAME
  ) {
    return DEFAULT;
  }

  return { className: 'hidden', displayMessage: () => <></> };
};

export default useEyebrowProps;
