import type { ReactNode } from 'react';

const tSupValue = {
  sup: (chunks: ReactNode) => (
    <sup className="font-serif font-semibold tracking-wider text-blue-200 italic">
      {chunks}
    </sup>
  ),
};

export default tSupValue;
